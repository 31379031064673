/* Copyright 2021 Google LLC

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License. */

@font-face {
  font-family: 'Montserrat Variablefont Wght';
  src: local('Montserrat Italic Variablefont Wght'), url(./fonts/Montserrat-Italic-VariableFont_wght.ttf) format('truetype');
  src: local('Montserrat Variablefont Wght'), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Opensans Variablefont Wdth Wght';
  src: local('Opensans Variablefont Italic Wdth Wght'), url(./fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf) format('truetype');
  src: local('Opensans Variablefont Wdth Wght'), url(./fonts/OpenSans-VariableFont_wdth,wght.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Opensans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #011f2a;
  min-height: 100%;
  padding-bottom: 40px;
}

#root {
  background-color: #084564;
  background-image: linear-gradient(#084564, #011f2a);
}

.container {
  padding-left: 0;
  padding-right: 0;
}

div {
  color: #f8fefe;  
}

.table {
  background-color: #011f2a;
  --bs-table-hover-color: #f8fefe;
  --bs-table-hover-bg: #084564;
}

thead {
  background-color: #e99500;
}

th {
  color: #f8fefe;
}

tr {
  color: #f8fefe;
  
}

.row {
  max-width: 100%;
}

a {
  color: #f8fefe;
}

.title {
  font-family: 'Montserrat Variablefont Wght', sans-serif;
  font-weight: 500;
}

h4 {
  font-size: 40px;
}

h5 {
  font-size: 20px;
  color: #9cf52d;
}

h6 {
  font-size: 20px;
  color: #e99500;
}

strong {
  color: #e99500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='checkbox'] {
  accent-color: #9cf52d;
}

.btn-link {
  color: #f8fefe;
}

.btn-link:hover {
  color: #00d3fb;
}

.btn-outline-primary {
  color: #00d3fb;
  border: 1px solid;
}

.btn-outline-primary:hover {
  color: #00d3fb;
  background-color: #084564;
  border: 1px solid #00d3fb;
}

.btn {
  border-radius: 20px;
}

.btn-success {
  border: 2px solid #2fbe00;
}

.btn-success:hover {
  border: 2px solid #9cf52d;
}

.col-md-8 {
  background-color: #011f2a;
}

.card {
  background-color: #011f2a;
  border-color: #084564;
}

.card-title {
  font-family: 'Montserrat Variablefont Wght', sans-serif;
  font-weight: 500;
}

.card-subtitle {
  font-family: 'Montserrat Variablefont Wght', sans-serif;
}

.modal-content {
  background-color: #084564;
  background-image: linear-gradient(#084564, #011f2a);
}

.btn-outline-secondary {
  color: #D8F1F1;
}

.btn-outline-secondary:hover {
  border: 1px solid #D8F1F1;
  background-color: #084564;
}

.form-text {
  color: #2fbe00;
}

.btn-primary {
background-color: #0a97f9;
border: 1px solid #00d3fb;
}

.btn-primary:hover {
  border: 1px solid #00d3fb;
}

.scroll-wrapper {
  overflow: auto;
  background-color: #f8fefe;
  border-radius: .25rem;
  margin-top: 10px;
  margin-bottom: 40px;
}

.scroll-wrapper-dark {
  overflow: auto;
  background-color: #011f2a;
  border-radius: .25rem;
  margin-top: 10px;
  margin-bottom: 40px;
  padding-top: .18rem;
}

.chart {
  height: 300px;
  width: 70%;
  min-width: 450px;
  margin-left: 0;
  padding-right: 1%;
}

.chart-bar {
  height: 300px;
  width: 28%;
  min-width: 350px;
  padding-left: 1%;
}

.image {
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 376px;
}
